<template>
    <el-scrollbar class="integration">
        <div class="integration-course">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="integration-breadcrumb">
                <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>一体化课程</el-breadcrumb-item>
            </el-breadcrumb>
            <!--<ul class="integration-course-list">
                <li v-for="item in integrationCourseList">
                    <div class="banner">
                        <img :src="item.img" alt="">
                    </div>
                    <span v-html="item.content"></span>
                </li>
            </ul>-->
            <div class="courses-list">
                <div class="course-item" v-for="(item, index) in integrationCourseList" :key="index">
                    <div class="course-cover">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="course-desc">
                        <h4>{{item.title}}</h4>
                        <p>
                            {{item.content}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        name: "integrationCourse",
        data() {
            return {
                integrationCourseList: [
                    {
                        title: '店铺开设',
                        img: require('../../assets/images/integrationCourse/yiti_03.jpg'),
                        content: '京东是国内著名的电子商务交易平台。作为电子商务的精英从业者，大 家要想更好地理解和应用电子商务，必须学会操作京东店铺交易平台。那么， 要经营好一家好的京东店铺，首先要学会如何开办一家京东店铺。 在本门课程中，我们将会学习：京东店铺类型的基本介绍；京东店铺开 办的资质要求和相应准备；京东店铺开办的详细操作步骤；京东店铺的入驻规则须知。 通过本课程的学习，希望大家能够快速、规范地开办一家京东店铺。'
                    },
                    {
                        title: '店铺装修',
                        img: require('../../assets/images/integrationCourse/yiti_02.jpg'),
                        content: '店铺装修是经营一家电商店铺必须重视的课题。有商品要交易就要有店铺。有店铺就一点要装修。店铺装修的优劣，直接决定了客户在店铺页面的 停留时间和下单意愿，最终影响到店铺的销售额。 所以，这本书的课程将会 教同学们：知道装修的意义及操作类型、了解视觉营销的基础知识、掌握京 东装修 PC 端和无线移动端后台的基础操作、从而实施京东店铺的装修。'
                    },
                    {
                        title: '商品管理',
                        img: require('../../assets/images/integrationCourse/yiti_01.jpg'),
                        content: '京东是国内著名的电子商务交易平台。作为平台的一些新商家或者新运 营可能会对产品上架和管理的一些细节等不太了解，容易忽视一些问题导致 犯错，最后导致违反平台规则，收到平台处罚。 在本门课程中，我们将会学习：商品的发布、优化、促销设置及维护和 商品上架的具体流程，包括类目设置、标题设置及详情设置等。 通过本课程的学习，希望大家能够很好的完成商品的上架及管理。'
                    },
                    {
                        title: '店铺引流',
                        img: require('../../assets/images/integrationCourse/yiti_06.jpg'),
                        content: '京东是国内著名的电子商务交易平台。作为电子商务的精英从业者，大 家要想更好地理解和应用电子商务，必须学会操作京东店铺交易平台。那么， 要经营好一家京东店铺，流量非常重要，怎么去创造流量，做活动是必不可 少的。 在本门课程中，我们将会学习：店铺流量的定义、掌握流量分布的情况、 无线流量的重要性、无线流量概况、无线引流的实施以及京东无线运营。了 解付费流量的使用平台和营销工具，是每个合格运营的必修课。掌握活动流 量的重要性，三类活动的介绍，活动引流的实施。'
                    },
                    {
                        title: '店铺营销',
                        img: require('../../assets/images/integrationCourse/yiti_05.jpg'),
                        content: '京东是国内著名的电子商务交易平台。作为电子商务的精英从业者，大 家要想更好地经营好自己的店铺，扩大业绩和销量，必须学会如何运用营销 知识来推广自己的店铺，获得更大的流量和收益。 在本门课程中，我们将会学习：营销中心使用简介、营销工具、场景营 销、用户营销和联合营销及推广的知识和操作步骤。 通过本课程的学习，希望大家能够运用这些营销知识去运作将来自己的 店铺。'
                    },
                    {
                        title: '创意创作',
                        img: require('../../assets/images/integrationCourse/yiti_04.jpg'),
                        content: '从手机应用的发展趋势来看，手机已经成了人们生活必不可少的部分。 尤其是在电商平台的购物，手机流量远远大于 PC 端流量。因此，无论是现 在、还是未来，要经营好一家京东店铺，都必须使一家店铺流量最大化。 在本门课程中，我们将会学习店铺引流介绍、流量分布的情况、流量的 重要性、视觉营销、付费流量概况及操作、通过内容创作引流、活动流量。 通过本课程的学习，希望大家能够快速掌握店铺引流的基本玩法'
                    },
                    {
                        title: '数据分析及搜索引擎优化',
                        img: require('../../assets/images/integrationCourse/yiti_07.jpg'),
                        content: '电子商务实现销售业绩,核心就是流量的导入和流量的转化。这一系列 工作的有效性,是通过定期进行数据分析来验证和优化的。因此,数据分析是 运营岗位的工作核心点。但是,由于店铺前期运营中缺乏对数据分析的认知, 认为过程枯燥、没有实际意义,缺少数据获取的渠道和分析方法。 所以，通过本课程，我们将学习:数据分析对品牌定位的意义、 数据分析对市场定位的意义、数据分析对用户定位的意义、数据分析对于店铺营销 的意义；运营数据搜集整理竞品数据搜集；新老客户数据整理；行业数据搜 集整理、京东搜索的重要'
                    },
                    {
                        title: '仓配客系统及工作流程',
                        img: require('../../assets/images/integrationCourse/yiti_08.jpg'),
                        content: '物流对于电商而言，是全供应链过程中不可或缺的关键环节。没有物流， 就无法实现货物从商家到消费者的转移。物流从一定程度上影响店铺的销 量，如何选择物流，这是店铺卖家值得思考的问题。京东物流经过近十年的 高速发展，已经成为京东集团商业运营的核心竞争力之一。京东物流不仅服 务着京东商城的自营商品，也服务着很多社会上的企业和商家。京东物流到 底有什么？优势是什么？店铺卖家又该如何使用京东物流呢？ 通过本课程的学习，希望大家能够了解京东物流及京东物流产品，选择 适合自己的物流服务，助力业务销售。'
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    .integration {
        width: 100%;
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .courses-list {
        height: calc(100% - 20px);
        padding: 20px 0;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        * {
            box-sizing: border-box;
        }
        .course-item {
            width: calc(50% - 17px);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            height: 198px;
            margin-bottom: 60px;
            &:nth-child(2n+1) {
                margin-right: 34px;
            }
            .course-cover {
                width: 140px;
                height: 100%;
                margin-right: 20px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .course-desc {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                h4 {
                    margin: 0 0 17px 0;
                    font-size: 16px;
                    color: #333333;
                }
                p {
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                    color: #666666;
                }
            }
        }
    }
    .integration-course {
        padding: 20px 0;
        height: calc(100% - 20px);
        width: 1200px;
        margin: 0 auto;
        .integration-breadcrumb {
            border-bottom: 1px solid #EAEAEA;
            color: #666666;
            height: 30px;
        }
        .integration-course-list {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                margin: 20px 0;
                display: flex;
                justify-content: start;
                .banner {
                    width: 140px;
                    height: 198px;
                    margin-right: 20px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                span {
                    flex: 1;
                    width: calc(100% - 160px);
                }
            }
        }
    }
</style>